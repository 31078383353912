import React from "react";
import { graphql } from "gatsby";
import { withUnpublishedPreview } from "gatsby-source-prismic";
import styled from "styled-components";

// Components
import Index from "./index";

const Page = styled.div`
  position: relative;
  height: 100vh;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  & h1 {
    width: 100%;
    margin: 0;

    line-height: 1 !important;
  }

  & p {
    width: 100%;
  }
`;

const NotFoundPage = ({ data }) => (
  <Page>
    <div>
      <div
        className="title-text text-21"
        dangerouslySetInnerHTML={{
          __html: data.prismicPageNotFound.data.title.html,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicPageNotFound.data.text.html,
        }}
      />
    </div>
  </Page>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    homepage: Index,
  },
});

export const query = graphql`
  {
    prismicPageNotFound {
      data {
        title {
          html
        }
        text {
          html
        }
      }
    }
  }
`;
